import { createEntityCollectionStore } from '@recruitee/ngrx';

import { EntityStoreKey, ReferralsCondition } from '../types';

const { actions, selectors, reducer, namespace } =
  createEntityCollectionStore<ReferralsCondition>()(EntityStoreKey.conditions);

export {
  actions as ConditionsActions,
  reducer as conditionsReducer,
  selectors as conditionsSelectors,
  namespace as ConditionsNamespace,
};
