import { createSingleEntityStore } from '@recruitee/ngrx';
import type { ReferralFilterOptions } from '@recruitee/referrals-types';

import { EntityStoreKey } from '../types';

const { actions, selectors, reducer, namespace } =
  createSingleEntityStore<ReferralFilterOptions | null>()(EntityStoreKey.offerFilters);

export {
  actions as OfferFiltersActions,
  reducer as offerFiltersReducer,
  selectors as offerFiltersSelectors,
  namespace as OfferFiltersNamespace,
};
