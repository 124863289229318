import { HttpErrorResponse } from '@angular/common/http';

import { createEntityCollectionStore, DEFAULT_SCOPE_NAME, pipeState, Scope } from '@recruitee/ngrx';
import type { ReferralOfferAssignment } from '@recruitee/referrals-types';

import { EntityStoreKey } from '../types';

export type FilterSet = {
  title: string;
  departmentId: string;
  countryCode: string;
  city: string;
  limit?: number;
  page?: number;
};

const { actions, selectors, reducer, namespace } =
  createEntityCollectionStore<ReferralOfferAssignment>()(EntityStoreKey.offerAssignments, {
    // eslint-disable-next-line max-lines-per-function
    customActions: adapter => ({
      fetchCollection: (state, payload: { params: FilterSet; scope?: string; replace?: boolean }) =>
        pipeState(
          state,
          next => (payload.replace ? adapter.resetAll(next) : next),
          next =>
            adapter.markAsPending(next, {
              scope: payload.scope || DEFAULT_SCOPE_NAME,
              entities: [],
            }),
        ),

      fetchCollectionSuccess: (
        state,
        payload: { data: ReferralOfferAssignment[]; scope?: string; replace?: boolean; meta: any },
      ) =>
        pipeState(
          state,
          next => (payload.replace ? adapter.setMany(next, [], { scope: payload.scope }) : next),
          next => adapter.addMany(next, payload.data, { scope: payload.scope }),
          next => adapter.setPaginationMeta(next, payload.meta, { scope: payload.scope || null }),
          next =>
            adapter.markAsLoaded(next, {
              scope: payload.scope || DEFAULT_SCOPE_NAME,
              entities: payload.data.map(e => e.id),
            }),
        ),
      fetchOne: (state, payload: { scope?: Scope; id: number }) =>
        adapter.markAsPending(state, { scope: payload?.scope || 'main', entities: [] }),
      fetchOneSuccess: (state, { data, scope }: { data: ReferralOfferAssignment; scope?: any }) =>
        pipeState(
          state,
          next =>
            adapter.markAsLoaded(next, {
              entities: [],
              scope: scope || DEFAULT_SCOPE_NAME,
            }),
          next =>
            adapter.addOne(next, data, {
              scope: scope || DEFAULT_SCOPE_NAME,
            }),
        ),
      fetchOneFail: (state, { error }: { error: HttpErrorResponse }) =>
        pipeState(
          adapter.markAsLoaded(state, {
            entities: [],
            scope: DEFAULT_SCOPE_NAME,
          }),
          next => adapter.setError(state, error),
        ),
    }),
  });

export {
  actions as OfferAssignmentsActions,
  reducer as offerAssignmentsReducer,
  selectors as offerAssignmentsSelectors,
  namespace as OfferAssignmentsNamespace,
};
